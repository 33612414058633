import React from "react";
import styled from "styled-components";
import { bp, colors } from "../styles/colors";

const CookieConsent = () => {
  return (
    <ConsentContainer>
      <ConsentTextBox>
        <ConsentTitle>Evästeet</ConsentTitle>
        <ConsentText>
          Painamalla "Hyväksy" hyväksyt sivustomme evästeiden käytön. Lue lisää.
        </ConsentText>
      </ConsentTextBox>
      <ConsentButton>Hyväksyn</ConsentButton>
    </ConsentContainer>
  );
};

export default CookieConsent;

const ConsentButton = styled.button`
  background: ${colors.grey8};
  padding: 1rem 2rem;
  margin: 0rem 4rem;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s;

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    background: ${colors.grey3};
    color: ${colors.grey8};
  }

  @media screen and (max-width: ${bp.mobile}) {
    margin: 2rem 0;
    margin-left: 4rem;
  }
`;

const ConsentText = styled.p`
  font-size: 1rem;
  color: ${colors.grey4};
  margin-top: 1rem;
`;

const ConsentTitle = styled.p`
  font-size: 2.5rem;
  font-weight: bold;
  color: ${colors.grey8};
`;

const ConsentTextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0rem 4rem;
  justify-content: center;
  height: 100%;
`;

const ConsentContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 25%;
  min-height: 130px;
  max-height: 160px;
  background: ${colors.black};
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: ${bp.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 280px;
    max-height: 320px;
  }
`;
