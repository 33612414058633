import React from "react";
import styled from "styled-components";
import { bp, colors } from "../styles/colors";
import Button from "./button";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ContactSection = ({ contacts }) => {
  const contact = contacts.filter(
    (person) => person.frontmatter.fullname === "Antti Ylitalo"
  )[0].frontmatter;

  return (
      <ContactContainer>
          <Anchor id="contactSection" />
          <ContactWrapper>
              <ContactTextWrapper>
                  <SmallTitle>Kuuntelemme toiveitasi</SmallTitle>
                  <MediumTitle>Ota yhteyttä</MediumTitle>
                  <ContactText>
                      Voit ottaa yhteyttä soittamalla, laittamalla meille
                      sähköpostia tai jättämällä yhteydenottopyynnön tällä
                      lomakkeella.
                      <br />
                      <br />
                  </ContactText>
                  <ContactTextContact>
                      {contact.fullname}
                      <br />
                      <A href={`tel:${contact.mobile}`}>
                          <StaticImage
                              src="../icons/phone.svg"
                              alt="phone"
                              width={18}
                              layout="constrained"
                          />
                          {contact.mobile}
                      </A>

                      <A href={`mailto:${contact.email}`}>
                          <StaticImage
                              src="../icons/mail.svg"
                              alt="mail"
                              width={18}
                              layout="constrained"
                          />
                          {contact.email}
                      </A>
                  </ContactTextContact>
                  <ResourceLink to="/laskutus">Laskutustiedot</ResourceLink>
              </ContactTextWrapper>
              <ContactCard
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  action="#"
                  id="contactCard"
              >
                  <input type="hidden" name="form-name" value="contact" />
                  <CardRow>
                      <ContactInput>
                          <ContactInputTitle>Nimi *</ContactInputTitle>
                          <ContactInputField
                              required
                              placeholder="Koko nimesi"
                              type="text"
                              name="name"
                          ></ContactInputField>
                      </ContactInput>
                      <ContactInput>
                          <ContactInputTitle>Yritys</ContactInputTitle>
                          <ContactInputField
                              placeholder="Yritys"
                              type="text"
                              name="company"
                          ></ContactInputField>
                      </ContactInput>
                  </CardRow>
                  <CardRow>
                      <ContactInput>
                          <ContactInputTitle>Sähköposti *</ContactInputTitle>
                          <ContactInputField
                              required
                              placeholder="Sähköpostiosoite"
                              type="email"
                              name="email"
                          ></ContactInputField>
                      </ContactInput>
                      <ContactInput>
                          <ContactInputTitle>Puhelin *</ContactInputTitle>
                          <ContactInputField
                              required
                              placeholder="Puhelinnumero"
                              type="tel"
                              name="mobile"
                          ></ContactInputField>
                      </ContactInput>
                  </CardRow>
                  <CardRow>
                      <ContactInput>
                          <ContactInputTitle>
                              Viesti / Tarjouspyyntö *
                          </ContactInputTitle>
                          <ContactInputField
                              required
                              placeholder="Kirjoita viestisi"
                              type="text"
                              name="message"
                          ></ContactInputField>
                      </ContactInput>
                  </CardRow>
                  <CardRow style={{ marginLeft: "1.5rem" }}>
                      <Button theme="dark" type="submit">
                          Lähetä
                      </Button>
                  </CardRow>
              </ContactCard>
          </ContactWrapper>
      </ContactContainer>
  );
};

const ContactInputField = styled.input`
  border: none;
  border-bottom: 1px solid ${colors.grey3};
  background: inherit;
  margin: 0.5rem 0;
  width: 100%;
  color: ${colors.grey5};
  font-size: 1.1rem;
  border-radius: none;

  &:focus {
    outline: none;
  }
`;

const ContactInputTitle = styled.h3`
  color: ${colors.white};
  font-size: 1.1rem;
  font-weight: bold;
`;

const ContactInput = styled.div`
  flex: 1 1 100%;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin: 1.5rem;
`;

const CardRow = styled.div`
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${bp.mobileSmall}) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const ContactCard = styled.form`
  background: ${colors.grey2};
  padding: 2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;

  @media screen and (max-width: ${bp.mobileSmall}) {
    padding: 0.5rem;
  }
`;

//Contact texts
const ContactTextContact = styled.div`
  //tässä oli p
  color: ${colors.white};
  font-size: 1.1rem;
  flex: 0 0 5%;
  margin-top: 1rem;
  line-height: 135%;

  -webkit-tap-highlight-color: transparent;
`;

const ContactText = styled.p`
  color: ${colors.white};
  max-width: 80%;
  font-size: 1.1rem;
  line-height: 135%;

  @media screen and (max-width: ${bp.mobile}) {
    font-size: 1.1rem;
    width: 100%;
  }

  @media screen and (max-width: ${bp.mobileSmall}) {
    max-width: 100%;
  }
`;

const MediumTitle = styled.h2`
  color: ${colors.white};
  font-size: 3rem;
  font-weight: 900;
  margin: 1rem 0;

  @media screen and (max-width: ${bp.mobile}) {
    font-size: 2rem;
  }
`;

const SmallTitle = styled.h3`
  color: ${colors.grey3};
  font-size: 1.1rem;
`;

const ContactTextWrapper = styled.div`
  flex: 0 1 40%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 1rem;

  @media screen and (max-width: ${bp.mobile}) {
    margin: 0 0 3rem 0;
  }
`;

const ContactWrapper = styled.div`
  width: 90%;
  max-width: 850px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media screen and (max-width: ${bp.mobile}) {
    flex-direction: column;
  }
`;

const ContactContainer = styled.section`
  width: 100%;
  padding: 10rem 0;
  background: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${bp.mobile}) {
    padding: 5rem 0;
  }
`;

const A = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;

  -webkit-tap-highlight-color: transparent;
  &:hover {
    color: #cacaca;
  }
`;
const ResourceLink = styled(Link)`
  margin-top: 2rem;
  color: ${colors.grey3};
  font-weight: 500;
  font-size: 1.1rem;

  -webkit-tap-highlight-color: transparent;
  &:hover {
    color: ${colors.white};
  }
`;

const Anchor = styled.div`
  position: absolute;
  height: 0;
  transform: translateY(-30rem);
  visibility: hidden;
  @media screen and (max-width: ${bp.mobile}) {
    transform: translateY(-45rem);
  }
`;

export default ContactSection;
