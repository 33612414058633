import React from "react";
import "../styles/layout.css";
import ContactSection from "./contactSection";
import Footer from "./footer";
import NavBar from "./navBar";
import { motion } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import CookieConsent from "./cookieConsent";


const Layout = (props) => {


  const contactData = useStaticQuery(graphql`
    query ContactQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "contacts" } } }
      ) {
        nodes {
          frontmatter {
            fullname
            email
            mobile
          }
        }
      }
    }
  `);

  return (
    <>

      <NavBar/>
      <motion.main
        initial={{ opacity: 0, x: -200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -200 }}
        transition={{
          type: "spring",
          mass: 0.35,
          stiffness: 75,
          duration: 0.1,
        }}
      >
        {props.children}
      </motion.main>
      <ContactSection contacts={contactData.allMarkdownRemark.nodes} />
      <Footer contacts={contactData.allMarkdownRemark.nodes} />
      {/** Tää lisätään sitku ollaan lopullisella domainilla. -Jaakko
          <CookieConsent />
      */}
    </>
  );
};


export default Layout;
