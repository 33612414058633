import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Button from './button.js'
import { colors, bp } from '../styles/colors.js'
import styled from 'styled-components'
import { isBrowser } from '../utils/windowChecker.js'
import Logo from '../assets/ylitaloLogo.svg'



const NavBar = (props) => {
    const [open, setOpen] = useState(false);
    const [ navPadding, setNavPadding ] = useState(true)
    const size = useWindowSize()


    if (typeof window !== 'undefined') {
    window.addEventListener('scroll', () => {
        if (window.scrollY > 70 && navPadding && !open) {
            setNavPadding(false)
        } else if (window.scrollY < 50 && !navPadding && !open) {
            setNavPadding(true)
        } else if (open)  {
            setNavPadding(true)
        }
    })


    }

    const navPadMobileTop = () => {
        if (window.scrollY > 70) {
            setOpen(!open)
            setNavPadding(!navPadding)
        } else if (window.scrollY < 50) {
            setOpen(!open)
            setNavPadding(true)
        }
    }

    const activeStyle = {color: colors.grey2}

    return (
        <NavContainer navPadding={navPadding} visible={true} id="navBar">
            <Nav navPadding={navPadding}>
                <LogoContainer style={{ webkitTapHighlightColor: 'transparent'}}>
                    <Link style={{ webkitTapHighlightColor: 'transparent'}} to="/">
                       <MediumLogo style={{ webkitTapHighlightColor: 'transparent'}}/>
                    </Link>
                </LogoContainer>

                {// Ison näytön menu. Jos näyttö yli bp.mobile
                }
                {size.width > parseInt(bp.mobile, 10) &&
                    <MenuContainer>
                        <MenuItem to="/" activeStyle={activeStyle}> Etusivu </MenuItem>

                        <MenuItem to="/palvelut" activeStyle={activeStyle}> Palvelut </MenuItem>

                        <MenuItem to="/referenssit" activeStyle={activeStyle}> Referenssit </MenuItem>

                        <MenuItem to="/vuokraamo" activeStyle={activeStyle}> Vuokraamo </MenuItem>
                    </MenuContainer>
                }
                {size.width > parseInt(bp.mobile, 10) &&
                    <Button to={"#contactSection"} internal={false}>Ota yhteyttä</Button>
                }


                {// Mobiilimenu - hampurilainen
                }
                
                {size.width <= parseInt(bp.mobile, 10) &&
                    <MobileBlank navPadding={navPadding}>
                        <Burger navPadding={navPadding} onClick={() => {
                            navPadMobileTop()
                        }}>
                            <Svg open={!open} width="30px" height="30px" viewBox="0 0 30 30">
                                <G className='top'>
                                    <rect x="0" y="13" className="st0" width="30" height="4"></rect>
                                </G>
                                <G className='bottom'>
                                    <rect x="0" y="13" className="st0" width="30" height="4"></rect>
                                </G>
                            </Svg>     
                        </Burger>
                    </MobileBlank>
                }
            
                

        
            </Nav>
            <Line navPadding={navPadding} open={open}/>
            {size.width <= parseInt(bp.mobile, 10) &&
                <MobileMenuContainer open={!open} navPadding={navPadding}>
                    <MobileLinkContainer>
                                <MenuItem mobilelink='true' className='mobileMenuItem' to="/" activeStyle={activeStyle} onClick={() => setOpen(!open)} state={{ id: props.pageID }}>Etusivu</MenuItem>

                                <MenuItem mobilelink='true' className='mobileMenuItem' to="/palvelut" activeStyle={activeStyle} onClick={() => setOpen(!open)} state={{ id: props.pageID }}>Palvelut</MenuItem>

                                <MenuItem mobilelink='true' className='mobileMenuItem' to="/referenssit" activeStyle={activeStyle} onClick={() => setOpen(!open)} state={{ id: props.pageID }}>Referenssit</MenuItem>

                                <MenuItem mobilelink='true' className='mobileMenuItem' to="/vuokraamo" activeStyle={activeStyle} onClick={() => setOpen(!open)} state={{ id: props.pageID }}>Vuokraamo</MenuItem>
                    </MobileLinkContainer>

                                <ButtonContainer><Button  className='mobileMenuButton' to={"#contactSection"} internal={false} onClick={() => setOpen(!open)} state={{ id: props.pageID }}>Ota yhteyttä</Button></ButtonContainer>
                </MobileMenuContainer>
            }
        </NavContainer>
    )
}

// Hook
const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      if(isBrowser) window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

const NavContainer = styled.div`
    position: fixed;
    width: 100%;
    transition: 0.5s top;
    background-color: white;
    box-shadow: ${props => !props.navPadding ? `0 5px 10px 0px rgba(0, 0, 0, 0.05)` : 'none'};
    z-index: 1000;
    top: 0;
    left: 0;
    overflow-x: hidden;
`

const LogoContainer = styled.div`
    max-width: 8rem;

    @media screen and (max-width: ${bp.mobile}) {
       max-width: 3rem;
    }
    z-index: 980;
`

const Nav = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: padding 0.3s;
    padding: ${props => props.navPadding ? '2rem' : '1rem'} 3rem;
    z-index: 100;

    @media screen and (max-width: ${bp.mobile}) {
        padding: ${props => props.navPadding ? '2rem' : '1rem'} 1rem;
    }
`
const Line = styled.hr`
    border-top: 1px solid ${colors.grey5};
    position: relative;
    transition: opacity 0.1s;
    opacity: ${props => props.navPadding  ? '1' : '0'};
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin-left: 3rem;
    margin-right: 3rem;
    z-index: 1000;

    @media screen and (max-width: ${bp.mobile}) {
        margin-left: 1rem;
        margin-right: 1rem;
    }
`

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const MobileBlank = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${props => !props.navPadding ? '74px' : '118px'};

    z-index: 100;
    background-color: ${colors.white};
    *{
        -webkit-tap-highlight-color: transparent;
    }
    *::selection {
        background-color: transparent;
    }
`

const MenuItem = styled(Link)`
    display: flex;
    cursor: pointer;
    color: ${colors.grey5};
    flex-direction: column;
    transition: 0.2s;
    text-decoration: none;
    margin: 1rem 3rem;
    font-size: ${props => props.mobilelink === 'true' ? '2rem' : '1.1rem'};
    font-weight: bold;

    -webkit-tap-highlight-color: transparent;

    @media screen and (max-width: ${bp.large}) {
        margin: 1rem 2rem;
    }

    @media screen and (max-width: 864px) {
        margin: 1rem 0.8rem;
    }

    @media screen and (max-width: ${bp.mobile}) {
        color: ${colors.grey5};
       font-weight: 450;
        margin: 0;
    }

    &:hover {
        color: ${colors.grey2};
    }
`

const MobileMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${colors.white};
    padding: 2rem;
    padding-top: ${props => !props.navPadding  ? '8rem' : '10rem'};
    position: fixed;
    top: 0;
    left: 0;
    min-height: 60%;
   
    transform: ${props => props.open ? 'translateY(-100%)' : 'translateY(0)'};
    width: 100%;
    transition: all ease 0.5s;
    z-index: 10;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);

     * {
         transition: 0.5s;
         opacity: ${props => props.open ? 0 : 1};
     }

     @media screen and (max-width: ${bp.mobile}) {
        justify-content: space-between;
        gap: 1rem;
    }

`

const MobileLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;

    gap: 1rem;
`

const Burger = styled.div`
    margin: 1rem;
    cursor: pointer;
    z-index: 1000;
    position: absolute;
    transition: top 0.2s;
    top: ${props => !props.navPadding  ? '7px' : '24px'};
    right: 1rem;
`
const Svg = styled.svg`
    * {
        transform-origin: center;
        transition: ease 0.1s;
    }
    .top {
        transform: ${props => props.open? 'translateY(-5px)' : 'rotate(45deg)'};
    }
    .bottom {
        transform: ${props => props.open? 'translateY(5px)' : 'rotate(-45deg)'};
    }
    z-index: 700;
`
const G = styled.g`
    
`

const ButtonContainer = styled.div`
    justify-self: flex-end;
`

const MediumLogo = styled(Logo)`
    width: 8rem;
    height: auto;
    z-index: 990;


    -webkit-tap-highlight-color: transparent;
`

export default NavBar