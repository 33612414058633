import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { colors } from "../styles/colors";
import fbIcon from "../icons/facebook.png";
import { bp } from "../styles/colors";
import Logo from "../assets/ylitaloLogo.svg";
import PoweredByNacu from "./poweredByNacu";
import { SmallMediumTitle } from "../styles/fonts";

const Footer = ({ contacts }) => {
    const activeStyle = { color: colors.grey2 };

    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterSection hideMobile="hide">
                    <FooterTitle>Valikko</FooterTitle>
                    <FooterLink activeStyle={activeStyle} to="/">
                        Etusivu
                    </FooterLink>
                    <FooterLink activeStyle={activeStyle} to="/palvelut">
                        Palvelut
                    </FooterLink>
                    <FooterLink activeStyle={activeStyle} to="/referenssit">
                        Referenssit
                    </FooterLink>
                    <FooterLink activeStyle={activeStyle} to="/vuokraamo">
                        Vuokraamo
                    </FooterLink>
                </FooterSection>

                <FooterSection>
                    <MediumLogo preserveAspectRatio="xMinYMin meet" />
                    <FooterText>
                        Rakennuspalveluita avaimet käteen -periaatteella.
                    </FooterText>
                    <FooterSocialIcons>
                        <SocialLink href="https://www.facebook.com/ylitalon">
                            <SocialIcon src={fbIcon} />
                        </SocialLink>
                    </FooterSocialIcons>
                    <ResourceContainer>
                        <FooterLink to="/kemikaaliluettelo">
                            Kemikaaliluettelo
                        </FooterLink>
                        <FooterLink to="/laskutus">Laskutustiedot</FooterLink>
                    </ResourceContainer>
                </FooterSection>

                <FooterSection>
                    <FooterTitle>Yhteystiedot</FooterTitle>
                    <FooterText>
                        Ylitalon Rakennuspalvelu Oy
                        <br />
                        Y-tunnus: 2905374-7
                        <br />
                        <br />
                        Ilmakantie 28, 16310 Virenoja
                        <br />
                        {contacts.map((person, index) => (
                            <div key={index}>
                                <br />
                                <FooterText
                                    style={{
                                        margin: "0 0 0 0",
                                        color: "black",
                                    }}
                                >
                                    {person.frontmatter.fullname}
                                    <br />
                                    {person.frontmatter.mobile}
                                    <br />
                                    {person.frontmatter.email}
                                    <br />
                                </FooterText>
                            </div>
                        ))}
                    </FooterText>
                </FooterSection>
            </FooterWrapper>

            <PoweredByNacu color={colors.grey5} width={"128px"} />
        </FooterContainer>
    );
};

const SocialIcon = styled.img`
    width: 40px;

    &:hover {
        opacity: 50%;
    }
`;

const SocialLink = styled.a``;

const FooterSocialIcons = styled.div`
    display: flex;
    width: 90%;
    justify-content: center;
`;

const FooterText = styled.p`
    color: ${colors.black};
    margin: 1rem 0;
    text-align: center;
    line-height: 135%;
    font-size: 1.1rem;
`;

const MediumLogo = styled(Logo)`
    width: 90%;
    padding-bottom: 0;
    height: 19%;
    max-width: 200px;
    max-height: 100px;
    height: auto;

    @media screen and (max-width: ${bp.mobile}) {
        max-width: 150px;
        margin: 0rem 0 -1rem 0;
    }
`;

const ResourceContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
`;

const FooterLink = styled(Link)`
    display: flex;
    cursor: pointer;
    color: ${colors.grey5};
    line-height: 135%;
    font-size: 1.1rem;
    flex-direction: column;
    transition: 0.2s;
    text-decoration: none;
    margin: 1rem 0rem;
    font-size: ${(props) => props.mobilelink && "1.6rem"};
    font-weight: bold;

    &:hover {
        color: ${colors.black};
    }

    @media screen and (max-width: ${bp.mobile}) {
        font-size: 1.5rem;
    }
`;

const FooterTitle = styled(SmallMediumTitle)`
    text-align: center;
`;

const FooterSection = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 25%;
    gap: 0.5rem;

    @media screen and (max-width: ${bp.mobile}) {
        align-items: center;
        margin: 2rem 0;
        width: 70%;
        gap: 1rem;
        display: ${(props) => (props.hideMobile === "hide" ? "none" : "flex")};
    }
`;

const FooterWrapper = styled.div`
    width: 95%;
    max-width: 750px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 2rem;
    margin-left: -2rem;

    @media screen and (max-width: ${bp.mobile}) {
        flex-direction: column;
        align-items: center;
        margin-left: 0rem;
    }
`;

const FooterContainer = styled.section`
    width: 100%;
    margin: 10rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: ${bp.mobile}) {
        margin: 3rem 0;
    }
`;

export default Footer;
