import styled from 'styled-components'

export const colors = {
    black: '#000000',
    white: '#FFFFFF',
    grey1: '#5B5B5B',
    grey2: '#2C2C2C',
    grey3: '#666666',
    grey4: '#9C9C9C',
    grey5: '#D0D0D0',
    grey6: '#EBEBEB',
    grey7: '#EFEFF0',
    grey8: '#F7F7F7',
    bg: 'rgb(243, 241, 238)',
    dark: '#0F0F0F',
}

export const fontSizes = {
    bigTitle: '16rem',
    smallTitle: '1rem',
    mediumTitle: '3rem',

}

export const mediumTitle = styled.h2`
    font-size: 3rem;
    font-weight: 900;
    color: ${colors.black};
`

export const bp = {
    mobile: '768px',
    mobileSmall: '440px',
    large: '1280px',
}