import styled from 'styled-components'
import { colors } from './colors'
import { bp } from './colors'


export const BlackText = styled.p`
    font-size: 1.1rem;
    color: ${colors.black};
    word-wrap: break-word;
    line-height: 135%;
  
`

export const GreyText = styled.p`
    color: ${colors.black};
    word-wrap: break-word;
    line-height: 135%;
    font-size: 1.1rem;
  
`

export const SmallTitle = styled.h3`
    font-size: 1.1rem;
    color: ${colors.grey5};
    word-wrap: break-word;

`

export const SmallHeader = styled.h3`
    font-size: 1.5rem;
    color: ${colors.black};
    word-wrap: break-word;

`

export const SmallMediumTitle = styled.h2`
    font-size: 2rem;
    color: ${colors.black};
    word-wrap: break-word;
    font-weight: 900;
 
`

export const MediumTitle = styled.h2`
    font-size: 3rem;
    font-weight: 900;
    color: ${colors.black};
    word-wrap: break-word;

    @media screen and (max-width: ${bp.mobile}) {
       font-size: 2rem;
    }
  
`

export const BigTitle = styled.h1`
    font-size: 10rem;
    font-weight: 715;
    color: ${colors.grey5};
    width: 100%;
    word-wrap: break-word;

`

export const HugeTitle = styled.h1`
    font-size: 16rem;
    font-weight: 700;
    color: ${colors.grey6};
    word-wrap: break-word;
`

//MOBILE TITLES






