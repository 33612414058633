import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
    FaArrowRight
} from 'react-icons/fa'
import scrollTo from 'gatsby-plugin-smoothscroll'

const Button = (props) => {
    return(
        <ButtonContainer theme={props.theme} onClick={props.onClick}>
            {props.internal 
                ? <ButtonLink to={props.to}> </ButtonLink>
                : <ButtonA onClick={() => scrollTo(props.to)}> </ButtonA>
            }
            <Content>
                {props.children}
                <ButtonBorder theme={props.theme}></ButtonBorder>
                <Arrow/>
            </Content>
        </ButtonContainer>
    )
    
}

export default Button

const Arrow = styled(FaArrowRight)`
    padding: 0rem 0rem 0 0.7rem;
`

const Content = styled.div`
    display: flex;
    font-size: 1rem;
`


const ButtonBorder = styled.div`
    position: absolute;
    display: block;
    cursor: pointer;
    background-color: transparent;
    top: -4px;
    left: -4px;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    border: 4px solid ${props => props.theme === 'dark' ? 'white' : 'black'};
    clip-path: inset(0px 66% 0px 0px);
    transition: ease 0.4s;
`
const ButtonContainer = styled.button`
    font-family: 'Arial';
    src: local('Arial'), url(/src/fonts/Arial.ttf) format('truetype');
    cursor: pointer;
    background-color: transparent;
    position: relative;
    max-width: fit-content;
    block-size: fit-content;
    display:block;
    border-radius: 2rem;
    color: ${props => props.theme === 'dark' ? 'white' : 'black'};
    border: 4px solid ${props => props.theme === 'dark' ? '#808080' : '#f2f2f2'};
    padding: 0.6rem 1.1rem;
    margin: 1rem 0 1rem 0;
    white-space: nowrap;
    z-index: 1;

    -webkit-tap-highlight-color: transparent;

    &:hover ${ButtonBorder} {
        clip-path: inset(0px 0px 0px 0px);
    }

    &::selection {
        background-color: transparent;
    }

`
const ButtonLink = styled(Link)`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
`
const ButtonA = styled.a`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
`
