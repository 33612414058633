import React from 'react'
import styled from 'styled-components'

const PoweredByNacu = (props) => {
    return(
        <LinkToNacu href='https://nacu.fi/' width={props.width}>
            <Svg color={props.color} maxWidth={props.width} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={props.width} height={props.width}  viewBox="0 0 256 110.7">
  
                <path d="M0,14h3.2v3.8h0.1c2.1-3.1,5.9-4.4,8.9-4.4c7.1,0,12.1,5.1,12.1,12c0,6.9-5,12-12.1,12c-3,0-6.8-1.3-8.9-4.4H3.2v17.9H0V14z
                    M12.2,16.4c-5.3,0-9.1,4-9.1,9.1s3.9,9.1,9.1,9.1c5.3,0,8.6-4,8.6-9.1S17.5,16.4,12.2,16.4z M40.7,37.5c-7.1,0-12.1-5.1-12.1-12
                    c0-6.9,5-12,12.1-12c7.1,0,12.1,5.1,12.1,12C52.9,32.3,47.8,37.5,40.7,37.5z M40.7,16.4c-5.3,0-8.6,4-8.6,9.1s3.3,9.1,8.6,9.1
                    c5.3,0,8.6-4,8.6-9.1S46,16.4,40.7,16.4z M59.7,14L65,32.6h0.1L71.2,14h3.3l6.1,18.5h0.1L85.9,14h3.8l-7.2,22.8h-3.6l-6-18.7h-0.1
                    l-6,18.7h-3.6L55.9,14H59.7z M96.2,26.3c0.4,4.6,3.9,8.2,8.4,8.2c3.4,0,6-1.9,7.3-4l2.5,2c-2.7,3.4-6,4.8-9.8,4.8
                    c-6.8,0-11.8-5.1-11.8-12c0-6.9,5-12,11.5-12c6.9,0,10.9,5.2,10.9,11.4v1.5H96.2z M111.7,23.7c0-4.3-2.8-7.3-7.4-7.3
                    c-4.2,0-8,3.6-8,7.3H111.7z M121.4,21.2c0-2-0.1-3.8-0.2-7.1h3.2v4.2h0.1c0.9-2.4,3.3-4.8,7.3-4.8c0.9,0,1.7,0.1,2.4,0.3l-0.6,3.2
                    c-0.4-0.1-1-0.2-1.9-0.2c-4.9,0-7.1,4.2-7.1,7.3v12.9h-3.2V21.2z M138.6,26.3c0.4,4.6,3.9,8.2,8.4,8.2c3.4,0,6-1.9,7.3-4l2.5,2
                    c-2.7,3.4-6,4.8-9.8,4.8c-6.8,0-11.8-5.1-11.8-12c0-6.9,5-12,11.5-12c6.9,0,10.9,5.2,10.9,11.4v1.5H138.6z M154.1,23.7
                    c0-4.3-2.8-7.3-7.4-7.3c-4.2,0-8,3.6-8,7.3H154.1z M186.3,36.9H183v-3.8h-0.1c-2.1,3.1-5.9,4.4-8.9,4.4c-7.1,0-12.1-5.1-12.1-12
                    c0-6.9,5-12,12.1-12c3,0,6.8,1.3,8.9,4.4h0.1V0h3.2V36.9z M174.1,34.5c5.3,0,9.1-4,9.1-9.1s-3.9-9.1-9.1-9.1c-5.3,0-8.6,4-8.6,9.1
                    S168.7,34.5,174.1,34.5z M206.8,0h3.2v17.9h0.1c2.1-3.1,5.9-4.4,8.9-4.4c7.1,0,12.1,5.1,12.1,12c0,6.9-5,12-12.1,12
                    c-3,0-6.8-1.3-8.9-4.4h-0.1v3.8h-3.2V0z M219,16.4c-5.3,0-9.1,4-9.1,9.1s3.9,9.1,9.1,9.1c5.3,0,8.6-4,8.6-9.1S224.3,16.4,219,16.4z
                    M245.1,32.9l7-18.9h3.9l-11.4,28.8c-1.4,3.5-3.2,5.8-7.2,5.8c-1,0-2,0-3-0.3l0.3-3.1c0.7,0.4,1.6,0.5,2.4,0.5
                    c2.2,0,3.4-1.5,4.4-4.1l1.7-4.4L233.9,14h4L245.1,32.9z M0,61.7h12.1v4.1c3.2-1.8,6.3-3.1,9.2-3.8c2.9-0.7,5.8-1.1,8.6-1.1
                    c3.7,0,7.1,0.5,10.2,1.6c3.1,1.1,5.8,2.7,8.1,4.8c2.3,2.1,4.1,4.8,5.4,7.9c1.3,3.2,1.9,6.8,1.9,11v23.6h-12V88.1
                    c0-2.6-0.4-5-1.1-7.1c-0.7-2.1-1.8-3.8-3.2-5.3c-1.4-1.4-3.1-2.5-5.1-3.3c-2-0.7-4.2-1.1-6.7-1.1c-1.3,0-2.6,0.1-4,0.4
                    c-1.4,0.3-2.7,0.7-4,1.2c-1.3,0.5-2.6,1.1-3.8,1.9c-1.2,0.7-2.3,1.6-3.3,2.5v32.4H0V61.7z M67.9,94.7c0-2.3,0.5-4.4,1.5-6.3
                    c1-1.9,2.5-3.5,4.5-4.9c2-1.4,4.5-2.4,7.5-3.2c3-0.7,6.5-1.1,10.5-1.1c3,0,6.1,0.2,9.4,0.7c3.3,0.4,6.6,1.1,10.1,2
                    c-0.1-1.5-0.6-2.9-1.4-4.2c-0.8-1.3-1.9-2.4-3.3-3.3c-1.4-0.9-3.1-1.7-5.1-2.2c-2-0.5-4.2-0.8-6.7-0.8c-1.4,0-2.9,0.1-4.5,0.2
                    c-1.6,0.2-3.3,0.4-5.1,0.7c-1.7,0.3-3.5,0.7-5.2,1.2c-1.7,0.5-3.3,1.1-4.7,1.8l-3.7-9.9c1.8-0.7,3.8-1.2,5.8-1.8
                    c2.1-0.5,4.2-1,6.4-1.4c2.2-0.4,4.3-0.7,6.5-0.9c2.2-0.2,4.3-0.3,6.3-0.3c9,0,15.7,2.3,20,6.9c4.4,4.6,6.6,11.6,6.6,21.1v20.9h-12.1
                    v-4.5c-1.2,0.7-2.6,1.4-4.1,2.1c-1.5,0.7-3.1,1.2-4.8,1.7c-1.7,0.5-3.6,0.9-5.6,1.2c-2,0.3-4.1,0.4-6.3,0.4c-3.7,0-7-0.4-9.9-1.2
                    c-2.8-0.8-5.2-1.9-7.1-3.4c-1.9-1.4-3.3-3.1-4.3-5.1C68.4,99,67.9,96.9,67.9,94.7z M80.6,94.2c0,0.8,0.2,1.5,0.6,2.3
                    c0.4,0.7,1.1,1.4,2,1.9c0.9,0.6,2,1,3.4,1.3c1.4,0.3,3.1,0.5,5.1,0.5c2.1,0,4.2-0.2,6.2-0.6c2-0.4,3.8-0.9,5.5-1.5
                    c1.7-0.6,3.3-1.3,4.6-2.2c1.4-0.8,2.5-1.7,3.5-2.5v-2.1c-1.8-0.5-3.7-1-5.5-1.3c-1.8-0.4-3.5-0.6-5.2-0.8c-1.6-0.2-3.2-0.3-4.6-0.4
                    c-1.5-0.1-2.7-0.1-3.8-0.1c-2.3,0-4.1,0.1-5.6,0.4s-2.7,0.7-3.6,1.2c-0.9,0.5-1.6,1.1-2,1.8C80.8,92.8,80.6,93.5,80.6,94.2z
                    M136.6,85.9c0-3.5,0.6-6.8,1.9-9.9c1.3-3.1,3.2-5.7,5.7-7.9c2.5-2.2,5.5-4,9.1-5.3c3.6-1.3,7.7-1.9,12.3-1.9c2,0,4,0.1,6.1,0.4
                    c2,0.2,4,0.6,5.9,1c1.9,0.4,3.7,1,5.3,1.6c1.7,0.6,3.1,1.3,4.3,2l-5.6,9.1c-2.6-1.2-5.1-2.1-7.5-2.7c-2.4-0.6-4.7-0.9-7.2-0.9
                    c-2.8,0-5.3,0.4-7.5,1.1c-2.2,0.7-4.1,1.7-5.6,3s-2.7,2.8-3.5,4.6c-0.8,1.8-1.2,3.7-1.2,5.7c0,2.1,0.4,4,1.3,5.7
                    c0.9,1.8,2.1,3.3,3.6,4.5c1.6,1.3,3.4,2.3,5.7,3c2.2,0.7,4.7,1.1,7.4,1.1c3,0,5.8-0.4,8.5-1.3c2.6-0.8,5.1-2,7.4-3.4l5.6,9.1
                    c-1.4,0.9-3,1.7-4.7,2.4c-1.7,0.7-3.5,1.4-5.4,1.9c-1.9,0.5-3.9,1-6,1.3c-2.1,0.3-4.3,0.5-6.5,0.5c-4.6,0-8.7-0.6-12.3-1.9
                    c-3.6-1.2-6.7-3-9.3-5.2s-4.5-4.8-5.8-7.8C137.3,92.7,136.6,89.5,136.6,85.9z M201.7,61.7h12v23.5c0,2.2,0.2,4.3,0.7,6.1
                    c0.5,1.8,1.3,3.4,2.5,4.7c1.2,1.3,2.8,2.3,4.7,3c2,0.7,4.4,1.1,7.4,1.1c1.4,0,2.8-0.1,4.2-0.4s2.8-0.7,4.1-1.2
                    c1.3-0.5,2.5-1.2,3.6-1.9c1.1-0.7,2.1-1.5,2.9-2.4V61.7H256v48.1h-12.1v-4.1c-3,1.8-5.9,3-8.9,3.8c-3,0.8-5.8,1.1-8.5,1.1
                    c-4.4,0-8.2-0.5-11.3-1.6c-3.1-1.1-5.7-2.6-7.7-4.6c-2-2-3.5-4.5-4.4-7.5c-0.9-2.9-1.4-6.3-1.4-10.1V61.7z"/>
            </Svg>

            <SvgPink color={'#FFD3DA'} maxWidth={props.width} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={props.width} height={props.width}  viewBox="0 0 256 110.7">
    
                <path d="M0,14h3.2v3.8h0.1c2.1-3.1,5.9-4.4,8.9-4.4c7.1,0,12.1,5.1,12.1,12c0,6.9-5,12-12.1,12c-3,0-6.8-1.3-8.9-4.4H3.2v17.9H0V14z
                    M12.2,16.4c-5.3,0-9.1,4-9.1,9.1s3.9,9.1,9.1,9.1c5.3,0,8.6-4,8.6-9.1S17.5,16.4,12.2,16.4z M40.7,37.5c-7.1,0-12.1-5.1-12.1-12
                    c0-6.9,5-12,12.1-12c7.1,0,12.1,5.1,12.1,12C52.9,32.3,47.8,37.5,40.7,37.5z M40.7,16.4c-5.3,0-8.6,4-8.6,9.1s3.3,9.1,8.6,9.1
                    c5.3,0,8.6-4,8.6-9.1S46,16.4,40.7,16.4z M59.7,14L65,32.6h0.1L71.2,14h3.3l6.1,18.5h0.1L85.9,14h3.8l-7.2,22.8h-3.6l-6-18.7h-0.1
                    l-6,18.7h-3.6L55.9,14H59.7z M96.2,26.3c0.4,4.6,3.9,8.2,8.4,8.2c3.4,0,6-1.9,7.3-4l2.5,2c-2.7,3.4-6,4.8-9.8,4.8
                    c-6.8,0-11.8-5.1-11.8-12c0-6.9,5-12,11.5-12c6.9,0,10.9,5.2,10.9,11.4v1.5H96.2z M111.7,23.7c0-4.3-2.8-7.3-7.4-7.3
                    c-4.2,0-8,3.6-8,7.3H111.7z M121.4,21.2c0-2-0.1-3.8-0.2-7.1h3.2v4.2h0.1c0.9-2.4,3.3-4.8,7.3-4.8c0.9,0,1.7,0.1,2.4,0.3l-0.6,3.2
                    c-0.4-0.1-1-0.2-1.9-0.2c-4.9,0-7.1,4.2-7.1,7.3v12.9h-3.2V21.2z M138.6,26.3c0.4,4.6,3.9,8.2,8.4,8.2c3.4,0,6-1.9,7.3-4l2.5,2
                    c-2.7,3.4-6,4.8-9.8,4.8c-6.8,0-11.8-5.1-11.8-12c0-6.9,5-12,11.5-12c6.9,0,10.9,5.2,10.9,11.4v1.5H138.6z M154.1,23.7
                    c0-4.3-2.8-7.3-7.4-7.3c-4.2,0-8,3.6-8,7.3H154.1z M186.3,36.9H183v-3.8h-0.1c-2.1,3.1-5.9,4.4-8.9,4.4c-7.1,0-12.1-5.1-12.1-12
                    c0-6.9,5-12,12.1-12c3,0,6.8,1.3,8.9,4.4h0.1V0h3.2V36.9z M174.1,34.5c5.3,0,9.1-4,9.1-9.1s-3.9-9.1-9.1-9.1c-5.3,0-8.6,4-8.6,9.1
                    S168.7,34.5,174.1,34.5z M206.8,0h3.2v17.9h0.1c2.1-3.1,5.9-4.4,8.9-4.4c7.1,0,12.1,5.1,12.1,12c0,6.9-5,12-12.1,12
                    c-3,0-6.8-1.3-8.9-4.4h-0.1v3.8h-3.2V0z M219,16.4c-5.3,0-9.1,4-9.1,9.1s3.9,9.1,9.1,9.1c5.3,0,8.6-4,8.6-9.1S224.3,16.4,219,16.4z
                    M245.1,32.9l7-18.9h3.9l-11.4,28.8c-1.4,3.5-3.2,5.8-7.2,5.8c-1,0-2,0-3-0.3l0.3-3.1c0.7,0.4,1.6,0.5,2.4,0.5
                    c2.2,0,3.4-1.5,4.4-4.1l1.7-4.4L233.9,14h4L245.1,32.9z M0,61.7h12.1v4.1c3.2-1.8,6.3-3.1,9.2-3.8c2.9-0.7,5.8-1.1,8.6-1.1
                    c3.7,0,7.1,0.5,10.2,1.6c3.1,1.1,5.8,2.7,8.1,4.8c2.3,2.1,4.1,4.8,5.4,7.9c1.3,3.2,1.9,6.8,1.9,11v23.6h-12V88.1
                    c0-2.6-0.4-5-1.1-7.1c-0.7-2.1-1.8-3.8-3.2-5.3c-1.4-1.4-3.1-2.5-5.1-3.3c-2-0.7-4.2-1.1-6.7-1.1c-1.3,0-2.6,0.1-4,0.4
                    c-1.4,0.3-2.7,0.7-4,1.2c-1.3,0.5-2.6,1.1-3.8,1.9c-1.2,0.7-2.3,1.6-3.3,2.5v32.4H0V61.7z M67.9,94.7c0-2.3,0.5-4.4,1.5-6.3
                    c1-1.9,2.5-3.5,4.5-4.9c2-1.4,4.5-2.4,7.5-3.2c3-0.7,6.5-1.1,10.5-1.1c3,0,6.1,0.2,9.4,0.7c3.3,0.4,6.6,1.1,10.1,2
                    c-0.1-1.5-0.6-2.9-1.4-4.2c-0.8-1.3-1.9-2.4-3.3-3.3c-1.4-0.9-3.1-1.7-5.1-2.2c-2-0.5-4.2-0.8-6.7-0.8c-1.4,0-2.9,0.1-4.5,0.2
                    c-1.6,0.2-3.3,0.4-5.1,0.7c-1.7,0.3-3.5,0.7-5.2,1.2c-1.7,0.5-3.3,1.1-4.7,1.8l-3.7-9.9c1.8-0.7,3.8-1.2,5.8-1.8
                    c2.1-0.5,4.2-1,6.4-1.4c2.2-0.4,4.3-0.7,6.5-0.9c2.2-0.2,4.3-0.3,6.3-0.3c9,0,15.7,2.3,20,6.9c4.4,4.6,6.6,11.6,6.6,21.1v20.9h-12.1
                    v-4.5c-1.2,0.7-2.6,1.4-4.1,2.1c-1.5,0.7-3.1,1.2-4.8,1.7c-1.7,0.5-3.6,0.9-5.6,1.2c-2,0.3-4.1,0.4-6.3,0.4c-3.7,0-7-0.4-9.9-1.2
                    c-2.8-0.8-5.2-1.9-7.1-3.4c-1.9-1.4-3.3-3.1-4.3-5.1C68.4,99,67.9,96.9,67.9,94.7z M80.6,94.2c0,0.8,0.2,1.5,0.6,2.3
                    c0.4,0.7,1.1,1.4,2,1.9c0.9,0.6,2,1,3.4,1.3c1.4,0.3,3.1,0.5,5.1,0.5c2.1,0,4.2-0.2,6.2-0.6c2-0.4,3.8-0.9,5.5-1.5
                    c1.7-0.6,3.3-1.3,4.6-2.2c1.4-0.8,2.5-1.7,3.5-2.5v-2.1c-1.8-0.5-3.7-1-5.5-1.3c-1.8-0.4-3.5-0.6-5.2-0.8c-1.6-0.2-3.2-0.3-4.6-0.4
                    c-1.5-0.1-2.7-0.1-3.8-0.1c-2.3,0-4.1,0.1-5.6,0.4s-2.7,0.7-3.6,1.2c-0.9,0.5-1.6,1.1-2,1.8C80.8,92.8,80.6,93.5,80.6,94.2z
                    M136.6,85.9c0-3.5,0.6-6.8,1.9-9.9c1.3-3.1,3.2-5.7,5.7-7.9c2.5-2.2,5.5-4,9.1-5.3c3.6-1.3,7.7-1.9,12.3-1.9c2,0,4,0.1,6.1,0.4
                    c2,0.2,4,0.6,5.9,1c1.9,0.4,3.7,1,5.3,1.6c1.7,0.6,3.1,1.3,4.3,2l-5.6,9.1c-2.6-1.2-5.1-2.1-7.5-2.7c-2.4-0.6-4.7-0.9-7.2-0.9
                    c-2.8,0-5.3,0.4-7.5,1.1c-2.2,0.7-4.1,1.7-5.6,3s-2.7,2.8-3.5,4.6c-0.8,1.8-1.2,3.7-1.2,5.7c0,2.1,0.4,4,1.3,5.7
                    c0.9,1.8,2.1,3.3,3.6,4.5c1.6,1.3,3.4,2.3,5.7,3c2.2,0.7,4.7,1.1,7.4,1.1c3,0,5.8-0.4,8.5-1.3c2.6-0.8,5.1-2,7.4-3.4l5.6,9.1
                    c-1.4,0.9-3,1.7-4.7,2.4c-1.7,0.7-3.5,1.4-5.4,1.9c-1.9,0.5-3.9,1-6,1.3c-2.1,0.3-4.3,0.5-6.5,0.5c-4.6,0-8.7-0.6-12.3-1.9
                    c-3.6-1.2-6.7-3-9.3-5.2s-4.5-4.8-5.8-7.8C137.3,92.7,136.6,89.5,136.6,85.9z M201.7,61.7h12v23.5c0,2.2,0.2,4.3,0.7,6.1
                    c0.5,1.8,1.3,3.4,2.5,4.7c1.2,1.3,2.8,2.3,4.7,3c2,0.7,4.4,1.1,7.4,1.1c1.4,0,2.8-0.1,4.2-0.4s2.8-0.7,4.1-1.2
                    c1.3-0.5,2.5-1.2,3.6-1.9c1.1-0.7,2.1-1.5,2.9-2.4V61.7H256v48.1h-12.1v-4.1c-3,1.8-5.9,3-8.9,3.8c-3,0.8-5.8,1.1-8.5,1.1
                    c-4.4,0-8.2-0.5-11.3-1.6c-3.1-1.1-5.7-2.6-7.7-4.6c-2-2-3.5-4.5-4.4-7.5c-0.9-2.9-1.4-6.3-1.4-10.1V61.7z"/>
    </SvgPink>
        </LinkToNacu>
    )
}

const Svg = styled.svg`
    position: absolute;
    fill: ${props => props.color};
    width: ${props => props.maxWidth};
`
const SvgPink = styled(Svg)`
    clip-path: circle(0% at 50% 100%);
    transition: 0.4s;
`

const LinkToNacu = styled.a`

-webkit-tap-highlight-color: transparent;
    position: relative;
    width: ${props => props.width};
    height: auto;
    &:hover ${SvgPink} {
        clip-path: circle(100.0% at 50% 100%);
    }
`

export default PoweredByNacu